@import url('https://fonts.googleapis.com/css2?family=Georama:wght@300;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  background-color: #FAF9F9;
  overflow-x: hidden;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Georama', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  color: #707070;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  line-height: 1.7;
}

/* Standard heading styles */
h1, h2, h3, h4, h5, h6 {
  /* font-family: 'Arial', sans-serif; */ /* Choose an appropriate font */
  font-family: 'Georama', sans-serif;
  font-weight: 700; /* Adjust font weight as needed */
  color: #42526E; /* Set text color */
  margin-bottom: 1rem; /* Add some bottom margin for spacing */
  line-height: 1.3;
}

/* Specific styles for each heading level (optional) */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

.yvr-box {
    display: flex;
    flex-direction: column;
    padding: 2rem 2.5rem;
    border: 1px solid #E0E0E0;
    background: #FFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    z-index: 1;
}

.yvr-box-clickable {
    position: relative;
}

.yvr-box-clickable:hover {
    cursor: pointer;
    /*outline: rgba(6, 155, 98, 0.2) 3px solid;*/
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.1);
}

.yvr-box-clickable.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.yvr-box-clickable.disabled:hover {
    outline: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.yvr-box-clickable.disabled::after {
    content: "Coming Soon";
    position: absolute;
    top: -1rem;
    background: #ddd;
    padding: 0.5rem 1rem;
    border-radius: 10rem;
}

.yvr-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.yvr-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 1.8rem 2.5rem;
  border-bottom: 1px solid #E0E0E0;
  background: #FFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
}

.page-wrapper {
  width: 100vw;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.payment-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  column-gap: 2rem;
  row-gap: 2rem;
  z-index: 1;
  max-width: 1200px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 900px) {
  .payment-wrapper {
    flex-direction: column;
  }
}

.payment-auth {
  width: 100%;
  max-width: 40rem;
  height: 36rem;
  overflow: hidden;
  position: relative;
  flex-grow: 2;
  padding: 0 !important;
}

.payment-screen {
  width: 100%;
  overflow: auto;
  padding: 2rem 2.5rem;
}

.payment-details {
  width: 20rem;
}

.yavrio-logo {
  width: 12rem;
}

.yavrio-mark {
  width: 24rem;
  position: fixed;
  bottom: -2rem;
  left: -2rem;
  z-index: 0;
}

.qr-wrapper {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr; /* Two equal-width columns */
  grid-column-gap: 3rem; /* 2rem gap between columns */
  justify-content: center; /* Aligns the columns horizontally in the center */
  align-items: center;
  margin-bottom: 1rem;
  max-width: 24rem;
}

.button-group {
  display: flex;
  column-gap: 0.7rem;
  position: sticky;
  bottom: 0;
  right: 0;
  width: 100%;
  margin-top: auto;
  justify-content: flex-end;
  flex-direction: row;
  background-color: #FFF;
  border-top: 1px solid #dedede;
  padding: 1.5rem 2rem 1.5rem 2rem;
}

button {
  padding: 0.5rem 2rem 0.5rem 2rem;
  border-radius: 10rem;
  font-weight: 600;
  font-size: 0.9rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.next-button {
  background-color: #069B62;
  color: #FFF;
}

.next-button:hover {
  background-color: #037E50;
}

.next-button:disabled {
  background-color: #e1e1e1;
}

.back-button {
  background-color: #fff;
  color: #999;
  border: solid 1px #999;
}

.back-button:hover {
  background-color: #fff;
  color: #555;
  border: solid 1px #555;
}

.back-button:disabled {
  background-color: #e1e1e1;
  color: white;
  border: none;
}

.input-field-div {
  display: grid;
  grid-template-columns: 0.5fr 1.2fr 0.7fr;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0.8rem;
}

.input-field-div input {
  border: solid 1px #dedede;
  padding: 0.3rem 0.7rem 0.3rem 0.7rem;
  font-size: 0.8rem;
}

.input-field-div img {
  width: 1rem;
}

.input-field-div p {
  margin-bottom: 0;
}

.rotate {
  -moz-transform:rotate(0deg);
  -webkit-transform:rotate(0deg);
  transform:rotate(0deg);
  -moz-transition: transform .5s linear;
  -webkit-transition: transform .5s linear;
  transition: transform .5s linear;
}

.rotate.rotate-on {
  -moz-transform:rotate(360deg);
  -webkit-transform:rotate(360deg);
  transform:rotate(360deg);
  -moz-transition: transform 0s linear;
  -webkit-transition: transform 0s linear;
  transition: transform 0s linear;
}

.yvr-combobox * {
  border-color: #dedede !important;
  border-radius: 0 !important;
}

.yvr-combobox input {
  border: none;
}

.yvr-combobox label {
  font-size: 0.9rem;
  color: #aaa;
}

.yvr-institution-logo {
  max-height: 4rem;
  max-width: 12rem;
}